import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import HomePage from './components/HomePage';
import Services from './components/Services';
import IntroVideo from "./components/IntroVideo";
import Header from './components/Header';
import Footer from './components/Footer';
import LegalDisclaimer from './components/LegalDisclaimer';

const App = () => {
  return (
    <div className="app">
      <IntroVideo />
      
      <I18nextProvider i18n={i18n}>
        <Router>
          <Header />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/Services" element={<Services />} />
              <Route path="/LegalDisclaimer" element={<LegalDisclaimer />} />
            </Routes>
          <Footer />
        </Router>  
      </I18nextProvider>
    </div>
  );
};

export default App;
