import React from 'react';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function Footer() {

    const navigate = useNavigate();

    const navigateWeb = () => {
        navigate('/LegalDisclaimer');
    };


  return (
    <div class="container" id="footerContainer">
    <div class="row">
          <div class="col-md-12">
              <footer class="footer">				
          <div class="container">
              <div class="row">
                  <div class="col-md-5 m-b-40">
                      <div class="footer-title m-t-5 m-b-20 p-b-8">
                          About us
                      </div>	
                      <p class="white-text">
                      Simple Solution empowers businesses with cutting-edge AI tools and seamless solutions, boost your efficiency with our innovative services.
                      </p>
                  </div>
                  <div class="col-md-1 m-b-30">
                      
                  </div>
                  <div class="col-md-3 m-b-30">
                      <div class="footer-title m-t-5 m-b-20 p-b-8">
                          Quick Links
                      </div>	
                      <div class="footer-links">
                        <span class="footerLinks" onClick={() => navigateWeb()} > Legal Disclaimer </span>
                      </div>
                  </div>
                  <div class="col-md-3 m-b-30">
                      <div class="footer-title m-t-5 m-b-20 p-b-8">
                          Support
                      </div>	
                      <div class="footer-links">
                          <a href="mailto:support@simplesolution.co.in">
                              support@simplesolution.co.in
                          </a>
                      </div>
  
                      <div class="footer-social-links m-t-30">
                          <li>
                            <a href="https://www.facebook.com/people/Simple-Solution/61553348165761/">
                            <FaFacebook className="socialIcon" />
                            </a>
                            <a href="https://www.instagram.com/simplesolutionsimpleliving">
                            <FaInstagram className="socialIcon" />
                            </a>
                            <a href="https://www.linkedin.com/company/simplesolutionsimpleliving/">
                            <FaLinkedin className="socialIcon" />
                            </a>
                          </li>
                      </div>
                  </div>
              </div>
          </div>
      </footer>
      <div class="footer-bottom">
         &copy; Simple Solution {new Date().getFullYear()}. All rights reserved.
      </div>
          </div>
      </div>
  </div>

  );
}

export default Footer;
