// src/components/SecondPage.js
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@reacticons/bootstrap-icons';
import { useNavigate } from 'react-router-dom';


const SecondPage = () => {
  const { t, i18n } = useTranslation();

  // Set the language based on stored value
  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  // Handle navigation to third page with selected category
  const handleNavigation = (category) => {
    localStorage.setItem('selectedCategory', category);
    if (category === 'easeAI') {
      window.location.href = 'https://easeai.vercel.app/'; 
    } else if (category === 'easeShop') {
      window.location.href = 'https://simplesolution.co.in/'; 
    }
  };
  const navigate = useNavigate();

  const navigateWeb = (clickVal) => {
    if(clickVal === 'back'){
      navigate('/');
    } 
  };
  return (
    <div className="container mt-5"> 
    <span onClick={() => navigateWeb('back')} className="btn btn-outline-light backBotton"><Icon name="arrow-left-short" /> {t('backBtnText')} </span>
      <h1 className="display-4" id="headOne">{t('selectService')} </h1>
      <div class="card-group">
        <div class="col-sm-3">
          <div class="card text-dark bg-warning mb-3" >
          
            <div class="card-header">{t('card1Header')}</div>
            <div class="card-body text-dark">
              <p class="card-text">{t('cardText')}</p>
              <button className="btn btn-dark" onClick={() => handleNavigation('easeAI')} > {t('btn1Text')} </button>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card text-dark bg-warning mb-3" >
            <div class="card-header">{t('card2Header')}</div>
            <div class="card-body text-dark">
              <p class="card-text">{t('cardText')}</p>
              <button className="btn btn-dark" onClick={() => handleNavigation('easeShop')} > {t('btn2Text')} </button>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default SecondPage;
