// src/components/HomePage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const setLanguage = (lang) => {
    localStorage.setItem('selectedLanguage', lang);
    navigate('/Services'); // Navigate to the second page
  };

  return (
    <div className="container mt-5"> 
      <h1 className="display-4" id="headOne">Welcome! </h1>
      <h4 className="display-9" id="headFourth">Select your preferred language: </h4>

      <div class="card-group">
        <div class="col-sm-3">
          <div class="card text-dark bg-warning mb-3" >
            <div class="card-header">English</div>
            <div class="card-body text-dark">
              <p class="card-text">Click below button to go for English language</p>
              <button className="btn btn-dark" onClick={() => setLanguage('en')} > English </button>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card text-dark bg-warning mb-3" >
            <div class="card-header">हिन्दी</div>
            <div class="card-body text-dark">
              <p class="card-text">हिंदी भाषा में जाने के लिए नीचे दिए गए बटन पर क्लिक करें</p>
              <button className="btn btn-dark" onClick={() => setLanguage('hi')} > हिंदी </button>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card text-dark bg-warning mb-3" >
            <div class="card-header">मराठी</div>
            <div class="card-body text-dark">
              <p class="card-text">मराठी भाषेसाठी जाण्यासाठी खालील बटणावर क्लिक करा</p>
              <button className="btn btn-dark" onClick={() => setLanguage('mr')} > मराठी </button>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card text-dark bg-warning mb-3" >
            <div class="card-header">Cymraeg</div>
            <div class="card-body text-dark">
              <p class="card-text">Cliciwch y botwm isod i fynd am y Gymraeg</p>
              <button className="btn btn-dark" onClick={() => setLanguage('cy')} > Cymraeg </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default HomePage;