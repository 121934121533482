import React from 'react';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();

  const navigateWeb = () => {
      navigate('/');
  };

  return (
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <div class="mx-auto order-0">
                {/* <a  href="./"><img src="./logo.png" alt="logo"></img> </a> */}
                <img src="./logo.png" alt="logo" class="navbar-brand mx-auto" onClick={() => navigateWeb()}/>
            </div>
        </div>
    </nav>
    
    
  );
}

export default Header;
