import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '@reacticons/bootstrap-icons';


function LegalDisclaimer() {

  const navigate = useNavigate();

  const navigateWeb = (clickVal) => {
    if(clickVal === 'back'){
      navigate('/');
    } 
  };

  return (
    <div className="container mx-auto mt-8">
      <span onClick={() => navigateWeb('back')} className="btn btn-outline-light"><Icon name="arrow-left-short" /> Back</span>
      <h1 className="text-2xl font-bold mb-4">Legal Disclaimer</h1>
      <p className="text-gray-600">
        The information provided on the Simple Solution website is for general informational purposes only. While we strive to keep the information up to date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
      </p>
      <p className="text-gray-600">
        In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
      </p>
      <p className="text-gray-600">
        Through this website, you can link to other websites that are not under the control of Simple Solution. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
      </p>
      <p className="text-gray-600">
        Every effort is made to keep the website up and running smoothly. However, Simple Solution takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
      </p>
    </div>
  );
}

export default LegalDisclaimer;
