// VidIntroVideoeo.js
import React, { useState, useEffect } from "react";
import desktopVideoSrc from "./Introduction_Desktop.mp4";
import mobileVideoSrc from "./Introduction_Mobile.mp4";
// import detectBrowser from 'detect-browser';


const IntroVideo = () => {
  
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
    // Detect device type
    const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 768); // Adjust the breakpoint as needed
    }, []);

  const handleVideoEnd = () => {
    const videoContainer = document.querySelector('.video-container');
    if (videoContainer) {
      videoContainer.style.display = 'none'; // Hide the container
    }
  };

  return (
    <div class="video-container">
      <video src={isMobile ? (mobileVideoSrc) : (desktopVideoSrc)} autoplay={"autoplay"} preload="auto" muted onEnded={handleVideoEnd} />
    </div>
  );
};

export default IntroVideo;
